<template>
  <div class="m-taomlar">
    <div class="container">
      <div class="row custom-row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 custom-col2">
          <dashboard></dashboard>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 custom-col2">
          <div class="manager-food-menu">
            <div class="row">
              <div class="col-lg-4 col-md-10 col-sm-12 col-12 custom-col">
                <router-link
                  to="/dashboard/eats/add"
                  class="food-card cus-f-card"
                >
                  <div class="custom-img">
                    <img src="../../../assets/images/icons/plus.svg" alt="" />
                  </div>
                  {{ $t("Taom qo‘shish") }}
                </router-link>
              </div>
              <div
                class="col-lg-4 col-md-10 col-sm-12 col-12 custom-col"
                v-for="index in 3"
                :key="index"
              >
                <food-card></food-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from "../../main/Dashboard.vue";
import foodCard from "../../elements/FoodCard";
export default {
  components: { Dashboard, foodCard },
};
</script>

<style>
</style>